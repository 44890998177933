import React from "react"
import { Disqus } from "gatsby-plugin-disqus"

const disqusConfig = {
  // url: `${config.siteUrl + location.pathname}`,
  // identifier: post.id,
  // title: post.title,
}

const CommentSection = () =>
  process.env.NODE_ENV === "production" && <Disqus config={disqusConfig} />

export default CommentSection
