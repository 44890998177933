import React from "react"

const AmazonItem = ({ src, title }) => (
  <iframe
    style={{ width: 120, height: 240 }}
    marginWidth="0"
    marginHeight="0"
    scrolling="no"
    frameBorder="0"
    title={title}
    src={src}
  ></iframe>
)

export default AmazonItem
