import React from "react"
import { Link } from "gatsby"
import Video from "../../components/video"

import Layout from "../../components/layout"
import LinkExternal from "../../components/link_external"
import Seo from "../../components/seo"
import Routes from "../../routes"
// import RichardDawkinsImg from "../../images/richard-dawkins.jpg"
import RichardDawkinsBowtie from "../../images/dawkins-bowtie.jpg"
import AmazonItem from "../../components/amazon_item"
import CommentSection from "../../components/comment_section"
import { useImpression } from "../../analytics/utils"

const RichardDawkins = ({ location }) => {
  useImpression("RichardDawkins")
  return (
    <Layout location={location}>
      <Seo
        title="Richard Dawkins | Quotes, Life, and Books"
        description="Richard Dawkins (Richard Dawkins Foundation) is an English-American writer, journalist, and world renowned atheist. He is famous for his religious intolerance and historic debate moments."
      />
      <h1>
        <span className="h1">Richard Dawkins</span>
        <span className="padding-top-10px d-block">
          Facts, Books, and Greatest Moments
        </span>
      </h1>
      <p>
        As one of the Four Horseman, Richard Dawkins is a fierce competitor
        against religions. He encourages critical thinking about topics,
        refusing to believe in something without first finding the evidence, and
        a rationale perspective to the reality of global religion and its impact
        on our society.
      </p>

      <h2>Greatest Moments</h2>
      <div className="flex-center">
        <Video controls url="https://youtu.be/DI4pAEjMTfM?t=188" />
      </div>
      <br />
      <div className="flex-center">
        <Video controls url="https://youtu.be/gtw6ABSkRnw?t=110" />
      </div>
      <br />
      <div className="flex-center">
        <Video controls url="https://youtu.be/Evkqh_mX2UE" />
      </div>
      <br />
      <h2>Who is Richard Dawkins?</h2>
      <p>
        Dawkins was born in Nairobi, Kenya in 1941. He is an evolutionary
        biologist who has gone viral for his arguments against religion. He was
        a professor at the University of Oxford for 13 years. Since then, he has
        become a famous author and atheist.
      </p>
      <p>
        He, similar to the other three horsemen of atheism, can so clearly
        articulate the issues with religious arguments. He's been keen to
        shutdown theists by clearly demonstrating the proposed argument from
        ignorance or demonstrable lack of evidence for claims. Dawkins is very
        public about his opinions and can be found all over the web: his web
        site, social media, and documentaries and debates.
      </p>
      <p>
        Dawkins has made a massive impact in the atheist community, and is one
        of the most articulate, intelligent, and pragmatic non-believers. During
        debates he is often able to flip a religious argument on its head. He
        has taken down many famous religious people, one of the best being with{" "}
        <Link to={Routes.GEORGE_PELL}>convicted child abuser George Pell</Link>.
        Find that full debate on our{" "}
        <Link to={Routes.DAWKINS}>debates page</Link>.
      </p>
      <h2>The God Delusion Book and Documentary</h2>
      <p>
        Dawkins has a{" "}
        <LinkExternal to="https://www.amazon.com/God-Delusion-Richard-Dawkins-ebook/dp/B003JTHWJQ/ref=as_li_ss_tl?&linkCode=ll1&tag=colinharris05-20&linkId=1ef11a9aceca08e1daa6d4c8d1462c81&language=en_US">
          book titled the "The God Delusion"
        </LinkExternal>
        , which ended up inspiring a documentary you can find here. The
        documentary covers the rationality of religion, and faith. Richard
        intends the film to strongly scrutinize the teachings and ideas of
        religion.
      </p>
      <div className="flex-center">
        <AmazonItem
          title="The God Delusion"
          src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B003JTHWJQ&asins=B003JTHWJQ&linkId=b17dfeb548d71946c60a8fec6f1f4a76&show_border=true&link_opens_in_new_window=true"
        />
      </div>
      <Video
        controls
        url="https://www.youtube.com/watch?v=RHAz-ULSdhI"
        width="400"
      />
      <br />
      <h2>Best Richard Dawkins Books</h2>
      <table>
        <tbody>
          <tr>
            <td className="text-center">
              <h4>The Selfish Gene (1976)</h4>
              <AmazonItem
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=0198788606&asins=0198788606&linkId=a88b2ace78088adf11f0dbdb08bbcdae&show_border=true&link_opens_in_new_window=true"
                title="The Selfish Gene"
              />
            </td>
            <td className="text-center">
              <h4>The Ancestor's Tale (2004)</h4>
              <AmazonItem
                title="The Ancestor's Tale"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B01K6G1H4K&asins=B01K6G1H4K&linkId=5992d4e0373245dad9d06d98babe48ec&show_border=true&link_opens_in_new_window=true"
              />
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <h4>The Greatest Show on Earth (2009)</h4>
              <AmazonItem
                title="The Greatest Show on Earth"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=1416594795&asins=1416594795&linkId=1a8891f8b4a1a3c36fe010331da1d10a&show_border=true&link_opens_in_new_window=true"
              />
            </td>
            <td className="text-center">
              <h4>Outgrowing God: A Beginner's Guide (2019)</h4>
              <AmazonItem
                title="Outgrowing God: A Beginner's Guide"
                src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=1984853910&asins=1984853910&linkId=ec5c356f2f12db8bf3170fa1180f75f9&show_border=true&link_opens_in_new_window=true"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <h4>The Magic of Reality (2011)</h4>
      <h4>The Blind Watchmaker (1986)</h4>
      <p>
        ...and many more! His books offer amazing insight into a different lens
        to view the world from. Dawkins covers a wide range of topics and is
        articulate and understandable in his books. Check them out and see what
        you think!
      </p>
      <h2>Best Debates with Dawkins</h2>
      <p>
        Richard Dawkins has participated in many debates, most of which are most
        certainly worth viewing. He's debated people like:
      </p>
      <ul>
        <li>John Lennox &mdash; 2007, 2008, 2008</li>
        <li>Giles Fraser</li>
        <li>Rabbi Shmuley</li>
      </ul>
      <h2>Dawkins versus The Killers' Brandon Flowers</h2>
      <div className="flex-center">
        <Video controls url="https://youtu.be/W-pr2PL-e9Y" />
      </div>
      <br />

      <div className="flex-center">
        <img
          src={RichardDawkinsBowtie}
          alt="Richard Dawkins is a famous atheist agnostic and evolutionary biologist."
          width="300"
        />
      </div>
      <hr />
      <p>
        Be sure to check out our{" "}
        <Link to={Routes.HITCHENS}>Christopher Hitchens</Link> to learn more
        about another awesome atheist.
      </p>
      <CommentSection />
    </Layout>
  )
}

export default RichardDawkins
